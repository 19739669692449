<script setup lang="ts">
import { Icon } from '@iconify/vue';

defineOptions({
  name: 'UiIcon'
});

const props = withDefaults(
  defineProps<{
    icon: string;
    inline?: boolean;
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl';
  }>(),
  { size: 'md' }
);
</script>

<template>
  <Icon
    :icon="props.icon"
    class="ui-icon"
    :class="[`size-${props.size}`, { 'ui-icon--inline': props.inline }]"
    :inline="props.inline"
  />
</template>

<style scoped lang="postcss">
.ui-icon--inline {
  display: inline;
}

.ui-icon {
  flex-shrink: 0;
  z-index: 0; /* solves issue where svgs in a leaflet map automatically get a z-index of 200 */
  aspect-ratio: 1;
  &:where(.size-sm) {
    font-size: var(--font-size-1);
  }
  &:where(.size-md) {
    font-size: var(--font-size-3);
  }
  &:where(.size-lg) {
    font-size: var(--font-size-4);
  }
  &:where(.size-xl) {
    font-size: var(--font-size-6);
  }
  &:where(.size-xxl) {
    font-size: var(--font-size-7);
  }
  &:where(.size-3xl) {
    font-size: var(--font-size-8);
  }
}
</style>
