import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams
} from '@/utils/ts-rest';

export type CreateClusterServiceOptions = { tsRestClient: TsRestClient };

export const createClusterService = ({
  tsRestClient
}: CreateClusterServiceOptions) => {
  return {
    getAll(query: InferFlatRequest<typeof contract.cluster.all>) {
      return apiHandler(tsRestClient.cluster.all, { query });
    },

    getById(params: InferFlatRequest<typeof contract.cluster.byId>) {
      return apiHandler(tsRestClient.cluster.byId, { params });
    },

    getInvites(params: InferFlatRequest<typeof contract.cluster.invites>) {
      return apiHandler(tsRestClient.cluster.invites, { params });
    },

    refuseInvite(clusterId: string) {
      return apiHandler(tsRestClient.cluster.refuseInvite, {
        params: { clusterId }
      });
    },

    acceptInvite(clusterId: string) {
      return apiHandler(tsRestClient.cluster.acceptInvite, {
        params: { clusterId }
      });
    },

    create(body: InferBody<typeof contract.cluster.create>) {
      return apiHandler(tsRestClient.cluster.create, { body });
    },

    update({
      clusterId,
      ...body
    }: InferFlatRequest<typeof contract.cluster.update>) {
      return apiHandler(tsRestClient.cluster.update, {
        params: { clusterId },
        body
      });
    },

    delete(params: InferParams<typeof contract.cluster.delete>) {
      return apiHandler(tsRestClient.cluster.delete, { params });
    },

    getMembers({
      clusterId,
      ...query
    }: InferFlatRequest<typeof contract.cluster.membersByClusterId>) {
      return apiHandler(tsRestClient.cluster.membersByClusterId, {
        params: { clusterId },
        query
      });
    },

    removeMember(params: InferParams<typeof contract.cluster.removeMember>) {
      return apiHandler(tsRestClient.cluster.removeMember, { params });
    },

    getMachines({
      clusterId,
      ...query
    }: InferFlatRequest<typeof contract.cluster.machinesByClusterId>) {
      return apiHandler(tsRestClient.cluster.machinesByClusterId, {
        params: { clusterId },
        query
      });
    }
  };
};
