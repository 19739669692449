import {
  ASSET_TAG_FILTER_PREFIX,
  type Override,
  type contract
} from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferQuery,
  type InferFlatRequest
} from '@/utils/ts-rest';

export const createAreaService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getAll(
      query: Override<
        InferQuery<typeof contract.area.all>,
        {
          filter: Omit<InferQuery<typeof contract.area.all>['filter'], 'tags'>;
        }
      >
    ) {
      const normalizedFilters: InferQuery<typeof contract.area.all>['filter'] =
        {
          tags: {}
        };
      for (const [name, filter] of Object.entries(query.filter)) {
        // pleasing the ts compiler -_-
        if (!normalizedFilters.tags) {
          normalizedFilters.tags = {};
        }

        if (name.startsWith(ASSET_TAG_FILTER_PREFIX)) {
          normalizedFilters.tags[name.replace(ASSET_TAG_FILTER_PREFIX, '')] =
            filter;
        } else {
          // @ts-expect-error argh !
          normalizedFilters[name] = filter;
        }
      }

      return apiHandler(tsRestClient.area.all, {
        query: { ...query, filter: normalizedFilters }
      });
    },

    getList(query: InferQuery<typeof contract.area.list>) {
      return apiHandler(tsRestClient.area.list, { query });
    },

    getOpenData(query: InferQuery<typeof contract.area.openData>) {
      return apiHandler(tsRestClient.area.openData, { query });
    },

    updateGeometry({
      areaId,
      geometry
    }: InferFlatRequest<typeof contract.area.updateGeometry>) {
      return apiHandler(tsRestClient.area.updateGeometry, {
        params: { areaId },
        body: { geometry }
      });
    }
  };
};
