import type { contract } from '@samsys/shared';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams,
  type InferQuery
} from '@/utils/ts-rest';
import type { TsRestClient } from './ts-rest.service';

export type CreateDataboxServiceOptions = { tsRestClient: TsRestClient };

export const createDataboxAssociationService = ({
  tsRestClient
}: CreateDataboxServiceOptions) => {
  return {
    getElligibleMachines(
      query: InferQuery<typeof contract.databoxMachine.elligible>
    ) {
      return apiHandler(tsRestClient.databoxMachine.elligible, { query });
    },

    getAllByDataboxId({
      databoxId,
      ...query
    }: InferFlatRequest<typeof contract.databox.associations>) {
      return apiHandler(tsRestClient.databox.associations, {
        query,
        params: { databoxId }
      });
    },

    getConflicts(body: InferBody<typeof contract.databoxMachine.conflicts>) {
      return apiHandler(tsRestClient.databoxMachine.conflicts, { body });
    },

    create(body: InferBody<typeof contract.databoxMachine.createAssociation>) {
      return apiHandler(tsRestClient.databoxMachine.createAssociation, {
        body
      });
    },

    update({
      databoxMachineId,
      ...body
    }: InferFlatRequest<typeof contract.databoxMachine.updateAssociation>) {
      return apiHandler(tsRestClient.databoxMachine.updateAssociation, {
        body,
        params: { databoxMachineId }
      });
    },

    delete({
      databoxMachineId
    }: InferParams<typeof contract.databoxMachine.deleteAssociation>) {
      return apiHandler(tsRestClient.databoxMachine.deleteAssociation, {
        params: { databoxMachineId }
      });
    },

    manageSplitting({
      databoxMachineId,
      ...body
    }: InferFlatRequest<typeof contract.databoxMachine.splitAssociation>) {
      return apiHandler(tsRestClient.databoxMachine.splitAssociation, {
        body,
        params: { databoxMachineId }
      });
    }
  };
};
